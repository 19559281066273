const CheckboxWithLabel = ({ id, label, onChange, checked }) => {
    return (
      <label htmlFor={id} style={{ display: "flex", alignItems: "center", cursor: "pointer", textShadow: "1px 1px 1px #000000"  }}>
        <input
          id={id}
          type="checkbox"
          onChange={onChange}
          checked={checked}
          style={{ marginRight: "8px" }}
        />
        {label}
      </label>
    );
  };

export default CheckboxWithLabel;