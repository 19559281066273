import { MultiLoader } from '../utils/MultiLoader';
import findIcon from '../utils/iconUtil';

function averageValuesByPeriod(data) {
    const dailySums = {};

    data.forEach(item => {
        // Parse the date and time
        const date = new Date(item.validTime.split('/')[0]);
        const hours = date.getUTCHours();
        const dayPart = (hours >= 8 && hours < 20) ? 'day' : 'night';

        // Create a key based on the date and day part
        const key = `${date.toISOString().split('T')[0]}_${dayPart}`;

        // Initialize the sums and counts if not already present
        if (!dailySums[key]) {
            dailySums[key] = { sum: 0, count: 0, period: dayPart };
        }

        // Add the value to the sum and increment the count
        dailySums[key].sum += (item.value * 1.8 + 32);
        dailySums[key].count += 1;
    });

    // Create an array to hold the averaged values
    const averagedData = [];

    // Calculate the averages
    for (const key in dailySums) {
        const averageValue = dailySums[key].sum / dailySums[key].count;
        averagedData.push({
            date: key.split('_')[0],
            period: dailySums[key].period,
            value: averageValue
        });
    }

    return averagedData;
}

async function DailyForecastUtil(data) {
    const properName = data.properName
    const { dailyForecastLink, currentConditionsLink } = data.dailyForecastData;
    const griddedForecastLink = dailyForecastLink.slice(0, -9);
    console.log(griddedForecastLink)
    
    const dailyForecastData = await MultiLoader(dailyForecastLink)
    .then((response) => response.json())
    .then((responseJSON) => responseJSON.properties.periods)
    
    const currentConditionsData = await MultiLoader(currentConditionsLink)
    .then((response) => response.json())
    .then((responseJSON) => responseJSON.properties)

    const dewPointData = await MultiLoader(griddedForecastLink)
    .then((response) => response.json())
    .then((responseJSON) => responseJSON.properties.dewpoint.values)
    .then((data) => averageValuesByPeriod(data))

    console.log(dewPointData)

    // Arrays for daily forecasts
    const timePeriod = [];
    const dailyTemp = [];
    const dailyDewPoint = [];
    const dailyPrecip = [];
    
    const iconArray = [];
    for (let i = 0; i < 14; i++) {
        let dewPointValue

        let precipValue = dailyForecastData[i].probabilityOfPrecipitation.value
        let timeValue = dailyForecastData[i].name
        let tempValue = (Math.round(dailyForecastData[i].temperature,2) + ' F')
        if (dewPointData[i]) {
            dewPointValue = (Math.round(dewPointData[i].value,2) + ' F')
        } else {
            dewPointValue = "POO"
        }

        let shortForecast = dailyForecastData[i].shortForecast
        let iconLink = findIcon(shortForecast)
        
        if (precipValue == null || precipValue == 0) {
            precipValue = "~0%";
        } else {
            precipValue += "%"
        }

        iconArray.push(iconLink)
        timePeriod.push(timeValue);
        dailyTemp.push(tempValue);
        dailyDewPoint.push(dewPointValue);
        dailyPrecip.push(precipValue);

        
    }
    
    // Arrays for current conditions
    const currentTemp = [];
    const currentDewPoint = [];
    const currentPrecip = [];
    const currentIcon = [];

    currentTemp.push(Math.round(currentConditionsData.temperature.value * 1.8 + 32,1) + ' F');
    currentDewPoint.push(Math.round(currentConditionsData.dewpoint.value * 1.8 + 32,1) + ' F');
    currentPrecip.push(dailyPrecip[0]);
    currentIcon.push(findIcon(currentConditionsData.textDescription));
    
    return ({
        "regionProperName": properName,
        "currentConditions": {
            "Temp": currentTemp,
            "Dew Point": currentDewPoint,
            "Precip Chance": currentPrecip,
            "Icons": currentIcon
        },
        "forecastData": {
            "Timeframe": timePeriod,
            "Temp": dailyTemp,
            "Dew Point": dailyDewPoint,
            "Precip Chance": dailyPrecip,
            "Icons": iconArray
        }
    });
}; 

export default DailyForecastUtil;