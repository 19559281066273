import OSWindow from "./OSWindow";
import { MultiLoader } from '../utils/MultiLoader';
import React, { useState, useEffect, useRef } from 'react';
import "../css/SunFax.css";

function timeStringUTC(dateObj) {
    const values = [dateObj.getUTCHours(), dateObj.getUTCMinutes(), dateObj.getUTCSeconds()]

    const finalValues = []
    for (let value in values) {
        if (values[value] < 10 && value != 0) {
            finalValues.push("0" + String(values[value]))
        } else {
            finalValues.push(String(values[value]))
        }
    }

    return (
        finalValues[0] + ":" + finalValues[1] + ":" + finalValues[2]
    )
}

function timeString(dateObj, regionData) {
    const timeString = dateObj.toLocaleString('en-US', { timeZone: regionData.sunFaxData.timezone })
    return(timeString.split(', ')[1]);
}

function SunFax({ regionData, sunData }) {

    const [sunriseState, setSunrise] = useState(null);
    const [sunsetState, setSunset] = useState(null);
    const [daylightState, setDaylight] = useState(null);
    const [sunAngle, setSunAngle] = useState(null);
    const [maxUV, setMaxUV] = useState(null);

    async function getUV() {
        const forecastData = await MultiLoader(regionData.sunFaxData.uvDaily)
        .then((response) => response.json())
        .then((responseJSON) => responseJSON["0"].UV_INDEX)
        .then((UVData) => setMaxUV(String(UVData)))
    }

    useEffect(() => {
        if (sunData && regionData) {
            const {sunrise, sunset, daylight, maxSunAngle} = sunData;
            
            setSunrise(timeString(sunrise, regionData));

            setSunset(timeString(sunset, regionData));

            setDaylight(timeStringUTC(daylight));

            setSunAngle(String(maxSunAngle.toFixed(2) + '°'));
            getUV();
        }
    }, [regionData, sunData])

    const windowProps = {
        className: "SunFax",
        disableTaskbar: true,
        removePadding: true,
        title: "GIMME_SUN_FAX.EXE"
    }

    return (
        <OSWindow {...windowProps}>
            <table className="SunTable">
            <tr>
                <th className="PurpleFlashText Header" colspan="2">TODAY'S SUN FAX</th>
            </tr>
            <tr>
                <td className="Bold">SUNRISE</td>
                <td>{sunriseState ? sunriseState : 'coming soon'}</td>
            </tr>
            <tr>
                <td className="Bold">SUNSET</td>
                <td>{sunsetState ? sunsetState : 'real soon'}</td>
            </tr>
            <tr>
                <td className="Bold">LENGTH OF DAYLIGHT</td>
                <td>{daylightState ? daylightState : 'super soon'}</td>
            </tr>
            <tr>
                <td className="Bold">MAXIMUM SUN ALTITUDE</td>
                <td>{sunAngle ? sunAngle : 'super soon'}</td>
            </tr>
            <tr>
                <td className="Bold">TODAY'S MAX UV</td>
                <td>{maxUV ? maxUV : 'super soon'}</td>
            </tr>
            <tr>
                <td className="Bold SmallText" colspan="2">YOUR COMPUTER REALLY JUST CALCULATED THE ORBIT OF THE EARTH AND YOU DIDN'T EVEN SAY THANK YOU... SMH</td>
            </tr>
            </table>
        </OSWindow>
    )}

export default SunFax;