//import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useAudio } from '../contexts/AudioContext';
import "../css/TopNavBar.css"

function TopNavBar({ items, linkTo }) {
    //const navigate = useNavigate();
    const { isPlaying, togglePlay } = useAudio();
  
    const allItems = {
      MusicNote:
        <img onClick={togglePlay} className="MusicNote" src="/gifs/music-note.gif"></img>,
      BackButton: 
        <Link style={{ position: 'absolute' }} to={ linkTo }><img className="BackButton" src="/gifs/back.gif"></img></Link>
    };
  
    return (
      <div className="TopNavBar">
        {items.map(item => allItems[item])}
      </div>
    );
  }

export default TopNavBar;